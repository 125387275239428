import { autoinject } from "aurelia-dependency-injection";
import { bindable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { QuestionnaireResponse } from "resources/classes/FhirModules/QuestionnaireResponse";
import { NitTools } from "resources/classes/NursitTools";
import { PatientItem } from "resources/classes/Patient/PatientItem";
import { fhirEnums } from "resources/classes/fhir-enums";
import { ConfigService } from "resources/services/ConfigService";
import { FhirService } from "resources/services/FhirService";
import { PatientService } from "resources/services/PatientService";
import { QuestionnaireService } from "resources/services/QuestionnaireService";
const moment = require("moment");

@autoinject
export class PatientPpr {
    @bindable patient : PatientItem;

    class1 : string = 'green';
    class2 : string = 'green';
    class3 : string = '';
    class4 : string = 'green';
    class5 : string = 'green';
    baseValue : number = 0;
    isoText : string;
    asSum : number = 0;
    questionnaire;
    questionnaireName : string;
    response;
    isEnabled : boolean;

    validFhirVersion: boolean = true;

    constructor(protected router : Router) {
        this.validFhirVersion = true; // FhirService.FhirVersion >= 4;
        const cfg = ConfigService.GetFormSettings('PPR2.0');
        this.questionnaireName = cfg?.questionnaireName || 'CareITPPR20E';
        if (cfg?.enabled === true) {
            this.questionnaire = QuestionnaireService.GetQuestionnaireDirect(this.questionnaireName);        
            this.isEnabled = true;
        } else {
            this.isEnabled = false;
        }
    }

    async patientChanged(p : PatientItem) {
        if (!p) {
            this.class1 = this.class2 = this.class4 = this.class5 = 'grey';
            return;
        }

        const isoSettings = await ConfigService.GetFormSettings('isolation');
        const isoResponse = QuestionnaireService.GetLatestResponseOfName(p, isoSettings.questionnaireName, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

        if (isoResponse) {
            this.class5 = isoResponse.status === 'in-progress' ? 'orange' : 'green';
            this.isoText = 'Isolierung';
        } else {
            this.class5 = 'grey';
            this.isoText = 'Keine Isolierung';
            this.asSum = 0;
        }

        const pprResponse = QuestionnaireService.GetLatestResponseOfName(p, this.questionnaireName, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        if (pprResponse) {
            this.class1 = this.class2 = this.class4 = pprResponse.status === 'in-progress' ? 'orange' : 'green';

            this.asSum = parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(pprResponse, 'NIT_PPREB_305'));
            if (isNaN(this.asSum)) this.asSum = 0;
            this.baseValue = parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(pprResponse, 'NIT_PPREB_300'));
            if (isNaN(this.baseValue))
                this.baseValue = 0;
        } else {            
            this.class1 = this.class2 = this.class4 = 'grey';
            this.asSum =this.baseValue = 0;
        }
    }

    openPPRQuestionnaire() {
        if (!this.questionnaire) {
            this.questionnaire = QuestionnaireService.GetQuestionnaireDirect(this.questionnaireName);
            if (!this.questionnaire) {
                alert(`Kein PPR Formular mit dem Namen "${this.questionnaireName} gefunden`);
                return;
            }
        }

        this.router.navigate(`questionnaire/${this.questionnaire.id}/${this.questionnaire.name}`);
    }
}
